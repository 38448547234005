.custom-table th,
.custom-table td {
    border: 1px solid #0F056B !important;
    background-color: white !important;
}

.custom-table {
    border-collapse: collapse;
}

.table {
    --bs-table-hover-bg: rgba(136, 204, 136, 0.452) !important;
}

.pagination{
    align-items: center !important;
}